import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TemplateTilePage from '../Components/Shared/TemplateTilePage';
// import NavigateBar from "../Components/Products/navigateBar";
import { useSelector } from 'react-redux';
import ProductsBannerSvg from '../assets/SVG/ReactSvg/ProductsBannerSvg';
import NotFound from '../Components/Products/NotFound';
import Loading from '../Components/Shared/Loading';
import ProductBanner from '../Components/Shared/ProductBanner';
import useGeneralImages from '../hooks/useGeneralImages';
import '../CSS/Category.css';
import { AdvancedImage } from '@cloudinary/react';
import { replaceColor } from '@cloudinary/url-gen/actions/adjust';
import { format } from '@cloudinary/url-gen/actions/delivery';
import FormatImageLink from '../Components/Shared/FormatImageLink';
import { png } from '@cloudinary/url-gen/qualifiers/format';
import { scale } from '@cloudinary/url-gen/actions/resize';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const Category2 = () => {
  const navigate = useNavigate();
  const { generalImages } = useGeneralImages();
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const decodeAndFormat = FormatImageLink();
  const { categoryID } = useParams();
  const [hover, setHover] = useState();
  const [navs, setNavs] = useState();
  const [catData, setCatData] = useState();
  const [found, setFound] = useState('');

  function getCategories(catID) {
    Axios.post(`${apiAddress}/products/getCategory`, {
      cat: catID,
    }).then((response) => {
      if (response.data.length > 0) {
        setCatData(response.data);
        setFound('found');
      } else {
        setFound('not found');
      }
    });
  }
  function getProductData() {
    Axios.post(`${apiAddress}/products/getCategory`, {}).then((response) => {
      setCatData(response.data);
      setFound('found');
    });
  }

  function getCategoriesForProducts() {
    Axios.post(`${apiAddress}/content/getGenericContent`, { tableName: 'dropdown_tabs' }).then(
      (response) => {
        setNavs(
          response.data
            .filter((i) => i.menu_name === 'Products')
            ?.sort((a, b) => a.tab_order - b.tab_order)
        );
      }
    );
  }

  function clickHandler(s) {
    if (s.hasSubCats === 'TRUE') {
      return navigate(`/categories/${encodeURIComponent(s.catName)}`);
    } else {
      return navigate(
        `/products/${encodeURIComponent(s.catName)}${s.linkParams ? `?${s.linkParams}` : ''}`
      );
    }
  }

  // useEffect(() => {
  //   if (!catData && !location?.state?.selectedCat) {
  //     getCategories(categoryID);
  //   } else {
  //     setCatData(location?.state?.selectedCat);
  //   }
  // }, []);

  useEffect(() => {
    document.title = `${categoryID || 'Products'} - National Public Seating`;
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (categoryID) {
      getCategories(categoryID);
    } else {
      getProductData();
    }
  }, []);

  useEffect(() => {
    if (categoryID) {
      getCategories(categoryID);
    } else {
      getProductData();
    }
  }, [categoryID]);

  useEffect(() => {
    getCategoriesForProducts();
  }, []);

  return (
    <div>
      {/* {categoryID ? (
        <NavigateBar series={categoryID} />
      ) : (
        <div className="navigate-bar"></div>
      )} */}

      {catData ?
        <TemplateTilePage
          list={catData}
          bannerClass={'banner-header'}
          clickHandler={clickHandler}
          banner={
            categoryID ?
              <ProductBanner
                text={categoryID}
                image={catData[0].parentCoverImage}
              />
            : mobileScreen.isMobile ?
              <div
                role='button'
                tabIndex={0}
                onClick={() => {
                  navigate(`/new`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    navigate(`/new`);
                  }
                }}
              >
                <img
                  className='mobile-new-products-banner'
                  src={
                    generalImages.find(
                      (i) => i.publicId === 'd730d5ae-b329-4d2e-9f46-dd6568ce58a0_ypxjlx'
                    )?.imageLink
                  }
                  alt='NEW PRODUCTS'
                />
              </div>
            : <div style={{ width: '100%' }}>
                <div className='explore-text'>Explore Our Products</div>
                <div className='basic-flex'>
                  {navs?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        className='product-group-card'
                        style={{
                          backgroundImage: `url('${
                            hover === item.link ?
                              'https://res.cloudinary.com/da3rom333/image/upload/v1734018869/DONT%20MOVE/Property_1_Hover_qmxmxd.png'
                            : 'https://res.cloudinary.com/da3rom333/image/upload/v1734018888/DONT%20MOVE/Property_1_Default_dojyel.png'
                          }')`,
                        }}
                        role='button'
                        tabIndex={0}
                        onMouseEnter={() => setHover(item.link)}
                        onMouseLeave={() => setHover()}
                        onClick={() => {
                          navigate(`${item.link}`);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            navigate(`${item.link}`);
                          }
                        }}
                      >
                        <AdvancedImage
                          cldImg={decodeAndFormat(item.icon_image_link)
                            ?.adjust(
                              hover === item.link ?
                                replaceColor('#ffffff')
                              : replaceColor('#B0C8C5')
                            )
                            ?.resize(scale().width(60))}
                          alt={`${item.title}-icon`}
                        />
                        <div className='basic-flex nav-descriptions'>
                          <div>{item.title}</div>
                          <div className='categorized-description'>{item.description}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>

          }
          navigateBarOriginal={categoryID ? categoryID : false}
        />
      : found === 'not found' ?
        <NotFound title={'category'} />
      : <Loading />}
    </div>
  );
};

export default Category2;
