import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { useParams } from 'react-router-dom';
import EditableGrid from './EditableGrid';
import Loading from '../Shared/Loading';
import GridTopComponent from './GridTopComponent';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const UseEditableGrid = () => {
  const { title } = useParams();
  const [data, setData] = useState();
  const [gridColumns, setGridColumns] = useState();
  const [tableInfo, setTableInfo] = useState();
  const [loading, setLoading] = useState();
  const axiosJWT = useAxiosJWT();

  function getGridColumns() {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridColumns`, { dataTitle: title })
      .then((response) => {
        setGridColumns(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getTableInfo() {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridTableInfo`, { dataTitle: title })
      .then((response) => {
        setTableInfo(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/get${title}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function postData(info) {
    setLoading(true);
    axiosJWT
      .post(`${apiAddress}/itemTable/get${title}`, { info: info })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function saveView(data, truncate) {
    setLoading(true);

    if (data?.length) {
      axiosJWT
        .post(`${apiAddress}/itemTable/saveChangeData`, {
          data: data,
          columns: gridColumns,
          tableInfo: tableInfo,
          preventTruncate: truncate,
        })
        .then(() => {
          getData();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }

  useEffect(() => {
    getGridColumns();
    getData();
    getTableInfo();
  }, []);

  return !loading && data ?
      <>
        <GridTopComponent
          title={title}
          postData={postData}
        />
        <EditableGrid
          columns={gridColumns}
          data={data}
          title={title}
          tableInfo={tableInfo}
          saveData={saveView}
        />
      </>
    : <Loading />;
};

export default UseEditableGrid;
