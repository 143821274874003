// import { useRef } from 'react';
// import '../../CSS/AdminPortal.css';
// import * as ExcelJS from 'exceljs';

// function UploadFile({ text, setData }) {
//   const convertExcelToObject = (file) => {
//     const reader = new FileReader();

//     reader.onload = async (event) => {
//       try {
//         // Read the file as ArrayBuffer
//         const arrayBuffer = event.target.result;

//         // Create a new ExcelJS workbook
//         const workbook = new ExcelJS.Workbook();

//         // Load the file into ExcelJS
//         await workbook.xlsx.load(arrayBuffer);

//         // Assuming you want to read the first sheet
//         const sheet = workbook.worksheets[0];
//         const rows = [];

//         // Iterate through each row of the sheet
//         sheet.eachRow((row, rowIndex) => {
//           const rowData = {};
//           row.eachCell((cell, colIndex) => {
//             const columnName = sheet.getRow(1).getCell(colIndex).text; // Use first row as headers
//             rowData[columnName] = cell.value;
//           });
//           rows.push(rowData);
//         });

//         // Set the converted data to state
//         setData(rows);
//       } catch (error) {
//         console.error('Error processing the Excel file:', error);
//       }
//     };

//     // Read file as ArrayBuffer
//     reader.readAsArrayBuffer(file);
//   };

//   const uploadFileHandler = (event) => {
//     const file = event.target.files[0];
//     convertExcelToObject(file);
//   };

//   const hiddenFileInput = useRef(null);

//   const handleClick = () => {
//     hiddenFileInput.current.click();
//   };

//   return (
//     <div className='uploadFile'>
//       <div>
//         <label htmlFor='file'>
//           <button
//             className='editableFormButton'
//             onClick={handleClick}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 handleClick();
//               }
//             }}
//           >
//             {text}
//           </button>
//           <div>
//             <input
//               onChange={uploadFileHandler}
//               ref={hiddenFileInput}
//               id='file'
//               type='file'
//               accept='.xlsx, .xls, .csv'
//               style={{ display: 'none' }}
//             />
//           </div>
//         </label>
//       </div>
//     </div>
//   );
// }

// export default UploadFile;

import { useRef } from 'react';
import Papa from 'papaparse';
import * as ExcelJS from 'exceljs';
import '../../CSS/AdminPortal.css';

function UploadFile({ text, setData, className = false }) {
  const processFile = (file) => {
    const fileType = file.type;

    if (fileType === 'text/csv' || file.name.endsWith('.csv')) {
      processCSV(file);
    } else if (
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.name.endsWith('.xlsx')
    ) {
      processExcel(file);
    } else {
      console.error('Unsupported file type. Please upload a CSV or Excel file.');
    }
  };

  const processCSV = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const csvString = event.target.result;

        // Use Papa Parse to parse the CSV string
        const { data, errors, meta } = Papa.parse(csvString, {
          header: true, // Treat the first row as headers
          skipEmptyLines: true,
        });

        if (errors.length) {
          console.error('Errors while parsing CSV:', errors);
          return;
        }

        const filteredData = data.map((row) => {
          const filteredRow = {};
          Object.keys(row).forEach((key) => {
            if (key.trim() !== '' && row[key] !== undefined && row[key] !== null) {
              filteredRow[key] = row[key];
            }
          });
          return filteredRow;
        });

        setData(filteredData);
      } catch (error) {
        console.error('Error processing the CSV file:', error);
      }
    };

    reader.readAsText(file);
  };

  const processExcel = (file) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const arrayBuffer = event.target.result;

        // Create a new ExcelJS workbook
        const workbook = new ExcelJS.Workbook();

        // Load the file into ExcelJS
        await workbook.xlsx.load(arrayBuffer);

        // Assuming you want to read the first sheet
        const sheet = workbook.worksheets[0];
        const rows = [];

        // Iterate through each row of the sheet
        const headerRow = sheet.getRow(1); // Use first row as headers
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex === 1) return; // Skip header row
          const rowData = {};
          row.eachCell((cell, colIndex) => {
            const columnName = headerRow.getCell(colIndex).value; // Match with header
            rowData[columnName] = cell.value;
          });
          rows.push(rowData);
        });

        setData(rows);
      } catch (error) {
        console.error('Error processing the Excel file:', error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const uploadFileHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      processFile(file);
    } else {
      console.error('No file selected.');
    }
  };

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className='uploadFile'>
      <div>
        <label htmlFor='file'>
          <button
            className={className ? className : 'editableFormButton'}
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
          >
            {text}
          </button>
          <div>
            <input
              onChange={uploadFileHandler}
              ref={hiddenFileInput}
              id='file'
              type='file'
              accept='.csv, .xlsx'
              style={{ display: 'none' }}
            />
          </div>
        </label>
      </div>
    </div>
  );
}

export default UploadFile;
