import { useEffect, useRef, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import BasicButton from '../NPSportal/basicButton';
import { BiSave } from 'react-icons/bi';
import { FiSave } from 'react-icons/fi';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const GridTopComponent = ({ title, postData }) => {
  const axiosJWT = useAxiosJWT();
  const [data, setData] = useState();
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const saveRef = useRef();

  async function getData() {
    switch (title) {
      case 'Thresholds':
        axiosJWT
          .post(`${apiAddress}/content/getGenericContent`, { tableName: 'trim_ranges' })
          .then((response) => {
            setData(response.data);
          })
          .catch((err) => {
            console.error(err);
          });
    }
  }

  const calculateFutureDate = (weeksToAdd) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + (weeksToAdd ? weeksToAdd : 17) * 7);
    return currentDate.toLocaleDateString();
  };

  function save() {
    setEdit(false);
    postData(inputValue || 17);
    setInputValue('');
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current?.contains(event.target) || saveRef.current?.contains(event.target)) {
        return;
      }
      setEdit(false);
      setInputValue('');
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  switch (title) {
    case 'Thresholds':
      return data ?
          <div className='trimester-container'>
            <div className='basic-flex'>
              <div className='header'>
                <div className='date-info'>
                  <div>
                    <strong>Today:</strong> {new Date().toLocaleDateString()}
                  </div>
                  <div>
                    <strong>Future Date:</strong> {calculateFutureDate(data[0]?.week_range)}
                  </div>
                </div>
                <div className='week-range-section'>
                  <div className='label'>Week Range:</div>
                  {edit ?
                    <>
                      {' '}
                      <input
                        className='value-trim-range-input'
                        ref={inputRef}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder={`${data[0]?.week_range || 17}`}
                        autoFocus
                      />{' '}
                      <div
                        className='save-div'
                        ref={saveRef}
                      >
                        <FiSave
                          onClick={save}
                          className='save-icon'
                        />
                      </div>
                    </>
                  : <div
                      onDoubleClick={() => setEdit(true)}
                      className='value-trim-range'
                    >
                      {data[0]?.week_range || 17}
                    </div>
                  }
                </div>
              </div>

              <div className='trimester-info'>
                {data
                  ?.filter((i) => i?.weeks_from_trim > 0)
                  ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
                  ?.map((trim, index) => (
                    <div
                      className='trimester-item'
                      key={index}
                    >
                      <span className='trimester-name'>
                        <strong>
                          {index === 0 ? 'Weeks Left in ' : 'Weeks into '}
                          {trim.trimester.replace('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}:
                        </strong>
                      </span>
                      <span className='trimester-weeks'>{trim.weeks_from_trim} weeks</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        : '';
    default:
      return null;
  }
};

export default GridTopComponent;
