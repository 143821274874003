import { AdvancedImage } from '@cloudinary/react';
import { replaceColor } from '@cloudinary/url-gen/actions/adjust';
import { format } from '@cloudinary/url-gen/actions/delivery';
import { png } from '@cloudinary/url-gen/qualifiers/format';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FormatImageLink from '../Shared/FormatImageLink';
import { scale } from '@cloudinary/url-gen/actions/resize';

const ProductCategoryImage = ({ category }) => {
  const decodeAndFormat = FormatImageLink();
  const navigate = useNavigate();
  const [inHoverMode, setInHoverMode] = useState(false);

  return (
    <Link
      className='cat-grid-div'
      to={`${category.link}`}
      onClick={() => navigate(`${category.link}`)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          navigate(`${category.link}`);
        }
      }}
      onMouseEnter={() => {
        setInHoverMode(true);
      }}
      onMouseLeave={() => {
        setInHoverMode(false);
      }}
    >
      <div className='product-cat-div'>
        <AdvancedImage
          cldImg={decodeAndFormat(category.image)
            ?.adjust(inHoverMode ? replaceColor('#00435b') : '')
            ?.delivery(format(png()))
            ?.resize(scale().width(30))}
          alt={`${category.title}-icon`}
        />

        <div>{category.title}</div>
      </div>
    </Link>
  );
};

export default ProductCategoryImage;
