import { useNavigate } from 'react-router-dom';

const PurchasingPortal = () => {
  const navigate = useNavigate();

  return (
    <div className='usersContainer'>
      <>
        <h2>Purchasing Portal</h2>
        <div className='purchasing-buttons'>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/Frames');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/Frames');
              }
            }}
          >
            Edit Frame Status
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/Ignore Inventory');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/Ignore Inventory');
              }
            }}
          >
            Edit Ignore Inventory
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/Inventory Items');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/Inventory Items');
              }
            }}
          >
            Edit Inventory Items
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/On Hand Stock');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/On Hand Stock');
              }
            }}
          >
            View On Hand Stock
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/Open POs');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/Open POs');
              }
            }}
          >
            View Open POs
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/Item Inventory');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/Item Inventory');
              }
            }}
          >
            View Item Inventory
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/employee-portal/Purchasing/Allocation Issues');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/employee-portal/Purchasing/Allocation Issues');
              }
            }}
          >
            View Allocation Issues
          </button>
          <button
            className='editableFormButton'
            onClick={() => {
              navigate('/grid/Thresholds');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/grid/Thresholds');
              }
            }}
          >
            View Thresholds
          </button>
        </div>
      </>
    </div>
  );
};

export default PurchasingPortal;
