import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState, useMemo } from 'react';
import excelClipboardCopy from '../../hooks/excelClipboardCopy';
import BasicButton from '../NPSportal/basicButton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../CSS/EditableGrid.css';
import UploadFile from '../Shared/UploadFile';

const EditableGrid = ({ columns, data, title, close = false, tableInfo, saveData }) => {
  const [rowData, setRowData] = useState(
    data.map((row) => ({
      ...row,
      _original: { ...row },
    }))
  );
  const [modifiedRows, setModifiedRows] = useState([]);
  const [batchImportData, setBatchImportData] = useState();
  const [saveEditData, setSaveEditData] = useState();
  const gridRef = useRef(null);
  const copyToClipboard = excelClipboardCopy();

  const columnDefs = columns?.map((column) => {
    const isEditable = column.editable === 'true';

    return {
      ...Object.fromEntries(
        Object.entries(column).map(([key, value]) => [
          key,
          value === 'true' ? true
          : value === 'false' ? false
          : value,
        ])
      ),
      width:
        columns?.length > 15 ? 110
        : columns?.length <= 7 ? 210
        : 130,
      ...(isEditable && {
        cellClassRules: {
          'cell-changed': (params) => {
            const originalValue = params.data._original?.[params.colDef.field];
            return originalValue !== undefined && params.value !== originalValue;
          },
        },
      }),
    };
  });

  const rowClassRules = {
    'row-duplicate': (params) => params.data.isDuplicate,
  };

  const gridOptions = {
    rowData: rowData,
    enableCellTextSelection: true,
    onCellValueChanged: (e) => onCellChanged(e),
    animateRows: true,
    editType: 'fullRow',
    columnDefs: columnDefs,
    rowClassRules: rowClassRules,
  };

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
      checkboxes: true,
      headerCheckbox: true,
      enableClickSelection: true,
    };
  }, []);

  const duplicateSelectedRows = () => {
    if (!gridRef?.current?.api) {
      console.error('Grid API is not available');
      return;
    }

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    if (selectedNodes.length === 0) {
      console.error('No rows selected to duplicate');
      return;
    }

    const sortedNodes = selectedNodes.sort((a, b) => a.rowIndex - b.rowIndex);

    const duplicatedRows = sortedNodes.map((node) => {
      const selectedData = node.data;
      return { ...selectedData, id: generateUniqueId(), isDuplicate: true };
    });

    const newRowData = [...rowData, ...duplicatedRows];
    setRowData(newRowData);

    duplicatedRows.forEach((row, i) => {
      gridRef.current.api.applyTransaction({
        add: [row],
        addIndex: sortedNodes[i].rowIndex + 1,
      });
    });

    setModifiedRows((prevModifiedRows) => [...prevModifiedRows, ...duplicatedRows]);
    const originalRowIds = selectedNodes.map((node) => node.id);
    gridRef.current.api.forEachNode((node) => {
      if (originalRowIds.includes(node.id)) {
        node.setSelected(true);
      }
    });
    gridRef.current.api.refreshCells({ force: true });
  };

  const deleteSelectedDuplicateRows = () => {
    if (!gridRef?.current?.api) {
      console.error('Grid API is not available');
      return;
    }

    // Get selected rows
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedRows = selectedNodes.map((node) => node.data);

    // Filter out rows that are duplicates and selected
    const rowsToKeep = rowData.filter(
      (row) => !selectedRows.some((selectedRow) => selectedRow.id === row.id && row.isDuplicate)
    );

    // Set the new row data
    setRowData(rowsToKeep);

    // Remove the selected duplicate rows from the grid
    const rowsToRemove = rowData.filter((row) =>
      selectedRows.some((selectedRow) => selectedRow.id === row.id && row.isDuplicate)
    );

    gridRef.current.api.applyTransaction({ remove: rowsToRemove });

    // Update modified rows state
    setModifiedRows((prevModifiedRows) =>
      prevModifiedRows.filter(
        (row) => !selectedRows.some((selectedRow) => selectedRow.id === row.id && row.isDuplicate)
      )
    );
  };

  const onCellChanged = (event) => {
    const updatedRow = event.data;

    setModifiedRows((prevModifiedRows) => {
      const rowExists = prevModifiedRows.find((row) => row.id === updatedRow.id);

      if (rowExists) {
        return prevModifiedRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      }

      const id = updatedRow.id || updatedRow.unique_id || generateUniqueId();
      updatedRow.id = id;

      const newRowData = rowData.map((row) => (row.id === updatedRow.id ? updatedRow : row));

      setRowData(newRowData);

      gridRef.current.api.refreshCells({ rowNodes: [updatedRow] });

      return [...prevModifiedRows, updatedRow];
    });
  };

  const copySelectedRowsToClipboard = () => {
    const isTextSelected = window.getSelection().toString().length > 0;

    if (isTextSelected) {
      return;
    }
    const selectedNodes = gridRef.current.api.getSelectedNodes();

    const allColumns = gridRef.current.api.getColumns();
    const visibleColumns = allColumns.filter((col) => col.getColDef().hide !== true);

    const columnOrder = visibleColumns.map((col) => col.getColId());
    const selectedData = selectedNodes.map((node) => node.data);

    const textToCopy = selectedData
      .map((row) =>
        columnOrder
          .map((colId) => {
            const value = row[colId];
            return `"${value}"`;
          })
          .join('\t')
      )
      .join('\r\n');

    copyToClipboard(textToCopy);
  };

  function generateUniqueId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    let uniqueId = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters[randomIndex];
    }

    return uniqueId;
  }

  function saveView() {
    setSaveEditData(modifiedRows);
  }

  const exportToCSV = () => {
    gridRef.current.api.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      allColumns: false,
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        copySelectedRowsToClipboard();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (batchImportData) {
      saveData(batchImportData);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (batchImportData) {
      saveData(batchImportData, false);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (saveEditData) {
      saveData(saveEditData, true);
      setSaveEditData();
    }
  }, [saveEditData]);

  return (
    <>
      <div style={{ width: '80%', margin: 'auto', position: 'relative' }}>
        {close ?
          <div
            role='button'
            aria-label='Back to previous page'
            tabIndex={0}
            onClick={() => {
              close();
            }}
            className='laminates-back-button'
            style={{ color: 'var(--darkblue)' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                close();
              }
            }}
          >
            {'< Back'}
          </div>
        : ''}
        <div className={`in-stock-table-header`}>
          <div
            style={{ alignItems: 'end' }}
            className='basic-flex'
          >
            <h2>{title}</h2>
          </div>
          <div className='basic-flex'>
            {tableInfo?.duplicate_rows ?
              <BasicButton
                type={'basic-white-button'}
                text={'Duplicate Rows'}
                onClick={duplicateSelectedRows}
              />
            : ''}
            {tableInfo?.duplicate_rows ?
              <BasicButton
                text='Delete Selected Duplicates'
                type='basic-white-button'
                onClick={deleteSelectedDuplicateRows}
              />
            : ''}
            {tableInfo?.export ?
              <BasicButton
                text={'Export'}
                type={'basic-white-button'}
                onClick={() => exportToCSV()}
              />
            : ''}
            {tableInfo?.import_batch_update ?
              <UploadFile
                text={'Import Batch Update'}
                setData={setBatchImportData}
                className={'basic-white-button basic-button-design'}
              />
            : ''}
            {tableInfo?.update_data ?
              <BasicButton
                text={'Save Data'}
                type={'basic-green-button'}
                onClick={saveView}
              />
            : ''}
          </div>
        </div>

        <div
          className='ag-theme-alpine'
          style={{ height: 650, margin: 'auto' }}
        >
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            rowSelection={rowSelection}
          />
        </div>
      </div>
    </>
  );
};

export default EditableGrid;
