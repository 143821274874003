import { useEffect, useState } from 'react';
import TemplateTile from './TemplateTile';
import TemplateTileElement from './TemplateTileElement';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../CSS/TemplateTile.css';
import '../../CSS/MobileApp/M_TemplateTile.css';
import BasicButton from '../NPSportal/basicButton';
import useGeneralImages from '../../hooks/useGeneralImages';
import NavigateBar from '../Products/navigateBar';
const TemplateTilePage = ({
  banner,
  list,
  clickHandler,
  elements,
  across = 4,
  onlyStrings = false,
  navigateBar = false,
  navigateBarOriginal = false,
  qsNavigate = false,
  middleElement = false,
  bannerClass = false,
  statusIcons = false,
  year = false,
  currentYear = false,
}) => {
  const { isMobile } = useSelector((state) => state.isMobile.value);
  const { generalImages } = useGeneralImages();
  const [fitAcross, setFitAcross] = useState(across);
  const [remainder, setRemainder] = useState();
  const [openYear, setOpenYear] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      setFitAcross(2);
    }
  }, [isMobile]);

  useEffect(() => {
    if (list) {
      if (list?.length % fitAcross === 0) {
        setRemainder(0);
      } else {
        setRemainder(fitAcross - (list?.length % fitAcross));
      }
    } else {
      if (elements?.length % fitAcross === 0) {
        setRemainder(0);
      } else {
        setRemainder(fitAcross - (elements?.length % fitAcross));
      }
    }
  }, [list, elements, fitAcross]);
  return (
    <div>
      <div className={`basic-width ${bannerClass ? bannerClass : 'banner'}  `}>
        {banner ?
          banner
        : year ?
          <div
            role='button'
            aria-label='banner'
            tabIndex={0}
            className={'year-banner'}
            onClick={() => {
              if (year !== currentYear) {
                setOpenYear(!openYear);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (year !== currentYear) {
                  setOpenYear(!openYear);
                }
              }
            }}
          >
            {year}
            {openYear || year === currentYear ?
              <BsChevronUp className='down-arrow expand-year' />
            : <BsChevronDown className='down-arrow expand-year' />}
          </div>
        : 'BANNER GOES HERE'}
      </div>
      {!navigateBar &&
        !navigateBarOriginal &&
        !qsNavigate &&
        banner?.props?.src?.includes('QuickShip_Banner') && (
          <div
            style={{ justifyContent: 'end' }}
            className='inner-padding basic-flex'
          >
            <div className='basic-blue-text'>For available overstock items, click here:</div>
            <BasicButton
              text={'Overstock Products'}
              type={'white-bordered-button'}
              onClick={() => navigate('/overstock-products')}
              icon={
                generalImages.find((i) => i.publicId === 'Icon_Quick_Ship_Laminates_B_bxo5qc')
                  ?.imageLink
              }
              buttonStyle={{ marginRight: '0px' }}
            />
          </div>
        )}
      {navigateBar ?
        <div className='mediaDownloads'>
          <div className='navigateBar inner-padding'>
            <a href='/media-center'>Media Center</a>
            <SlArrowRight />
            <div className='current'>{navigateBar}</div>
          </div>
        </div>
      : navigateBarOriginal ?
        <NavigateBar series={navigateBarOriginal} />
      : qsNavigate ?
        <div className='mediaDownloads'>
          <div className='navigateBar basic-width'>
            <a href='/quickship'>Quickship Products</a>
            <SlArrowRight />

            <div className='current'>{qsNavigate}</div>
          </div>
        </div>
      : ''}

      {middleElement ? middleElement : ''}
      <div
        className={` template-tile-div basic-width ${onlyStrings ? 'columns4' : ''} ${
          year && !openYear && year !== currentYear ? 'hide' : ''
        }`}
      >
        {(!year || openYear || year === currentYear) &&
          list?.map((i, index) => (
            <TemplateTile
              key={index}
              item={i} //it is putting the whole list in as the item, not each one
              statusIcons={statusIcons}
              clickHandler={clickHandler}
              onlyStrings={onlyStrings}
            />
          ))}
        {elements?.map((e, i) => {
          return (
            <TemplateTileElement
              key={i}
              element={e}
            />
          );
        })}
        {list && remainder ?
          Array.from(Array(remainder), (e, i) => {
            return (
              <TemplateTile
                key={i}
                empty={true}
              />
            );
          })
        : ''}
        {elements && remainder ?
          Array.from(Array(remainder), (e, i) => {
            return (
              <TemplateTileElement
                key={i}
                empty={true}
              />
            );
          })
        : ''}
        <div
          className='pages'
          style={{ display: 'flex' }}
        >
          {/* {flatList ? (
            <Paginator
              currentPage={page}
              totalPages={lastPage}
              setCurrentPage={setPage}
              setShow={setShow}
              show={show}
            />
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TemplateTilePage;
